<template>
  <v-container>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-toolbar
          flat
          style="background-color: #fafafa"
        >
          <v-toolbar-title>
            <div class="headline font-weight-black">
              Mis Favoritos
            </div>
          </v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            placeholder="Buscar por cargo, área o palabra clave"
            outline
            single-line
            append-icon="search"
          />
        </v-toolbar>
      </v-flex>
      <v-flex xs12>
        <v-container v-if="filteredOffers.length > 0">
          <v-layout
            v-for="offer in filteredOffers"
            :key="`offer-${offer.uuid}`"
            style="margin-bottom: 10px"
          >
            <v-flex xs12>
              <PublicOfferCard
                :offer="offer"
                :disable-favourite="true"
                @setFavourite="isFavourite => offer.is_favourite = isFavourite"
              />
            </v-flex>
          </v-layout>
        </v-container>
        <v-container v-else>
          <v-layout>
            <v-flex xs12>
              <NoResults />
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import PublicOfferCard from '@/components/offers/PublicOfferCardV2.vue';
import NoResults from '@/components/offers/NoResults.vue';

export default {
  name: 'MyFavourites',
  components: {
    PublicOfferCard,
    NoResults,
  },
  mixins: [
    JWTConfigMixin,
  ],
  data() {
    return {
      offers: [],
      search: '',
    };
  },
  computed: {
    lowercaseSearch() {
      return this.search.toLowerCase();
    },
    filteredOffers() {
      if (this.search) {
        return [...this.offers].filter(data => {
          const positionLookup = data.position.toLowerCase().includes(this.lowercaseSearch);
          const descriptionLookup = data.description.toLowerCase()
            .includes(this.lowercaseSearch);
          return positionLookup || descriptionLookup;
        });
      }
      return [...this.offers];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadOffers();
    });
  },
  methods: {
    loadOffers() {
      this.$store.commit('setLoading');
      axios.get(this.$store.state.endpoints.candidates.candidateFavourites, this.jwtConfig)
        .then(response => {
          this.offers = response.data;
          this.$store.commit('unsetLoading');
        })
        .catch(error => {
          console.error(error);
          this.$store.commit('unsetLoading');
        });
    },
  },
};
</script>
