<template>
  <v-card
    :class="$vuetify.breakpoint.xsOnly
      ? 'offer-card' : 'offer-card-responsive'"
  >
    <v-card-text
      :class="$vuetify.breakpoint.xsOnly
        ? 'pt-2' : ''"
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          xs2
          md1
          :class="$vuetify.breakpoint.xsOnly
            ? 'mt-1 pl-0' : ''"
        >
          <v-avatar
            color="#E0E0E0"
            tile
            :size="$vuetify.breakpoint.xsOnly
              ? '43' : '78'"
            :class="$vuetify.breakpoint.xsOnly
              ? 'icon-company' : 'icon-company-responsive'"
          >
            <v-icon
              color="E9E9E9"
            >
              {{ jobIcon() }}
            </v-icon>
          </v-avatar>
        </v-flex>
        <v-flex
          xs8
          md10
          :class="$vuetify.breakpoint.mdAndUp
            ? 'pl-3 d-flex align-center' : 'pl-2'"
        >
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
              md7
              :class="$vuetify.breakpoint.xsOnly
                ? 'mb-2' : 'mt-0'"
            >
              <template v-if="offer.public_url">
                <router-link
                  :class="$vuetify.breakpoint.xsOnly
                    ? 'link_to_offer offer-title-responsive' : 'link_to_offer offer-title'"
                  style="text-decoration: None;"
                  :to="offer_link"
                >
                  {{ offer.computed_title }}
                </router-link>
                <span
                  :class="$vuetify.breakpoint.xsOnly
                    ? 'link_to_offer offer-title-responsive' : 'link_to_offer offer-type-contract'"
                >
                  {{ contractType() }}
                </span>
                <v-chip
                  v-if="offer.is_brand_new"
                  color="cyan darken-1"
                  dark
                  small
                >
                  <b>Nuevo</b>
                </v-chip>
                <v-chip
                  v-if="offer.user_is_applying"
                  color="green"
                  small
                  dark
                >
                  <b>Ya postulaste</b>
                </v-chip>
              </template>
              <template
                v-else
              >
                {{ offer.computed_title }}
                <v-chip
                  color="gray"
                  small
                  dark
                >
                  <b>Cerrado</b>
                </v-chip>
              </template>
              <p
                :class="$vuetify.breakpoint.xsOnly
                  ? 'offer-subtitle-responsive mb-0' : 'offer-subtitle mb-0'"
              >
                <template v-if="!offer.is_confidential">
                  {{ offer.company }},
                </template>

                {{ offer.city_name }}, {{ offer.region_name }}
              </p>
            </v-flex>
            <v-flex
              v-if="showPerks"
              xs12
              md2
              :class="$vuetify.breakpoint.mdAndUp
                ? 'mt-3 pl-5' : 'mt-0 pb-1'"
            >
              <v-tooltip
                v-for="(perk, index) in offer.perks"
                :key="`${perk}-${index}`"
                class="pl-2"
                top
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                  >
                    {{ perk.icon }}
                  </v-icon>
                </template>
                <span>{{ perk.name }}</span>
              </v-tooltip>
            </v-flex>
            <v-flex
              xs12
              md3
              :class="$vuetify.breakpoint.lgAndUp
                ? 'pl-5' : 'mt-0 py-0'"
            >
              <p
                :class="$vuetify.breakpoint.xsOnly
                  ? 'time-since-created-responsive mb-0' : 'time-since-created'"
              >
                Publicado {{ offer.time_since_created }}
              </p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          xs2
          md1
          lg1
          align-self-start
          class="text-xs-right mt-0"
        >
          <FavouriteButton
            class="mt-0"
            :offer="offer"
            @setFavourite="isFavourite => $emit('setFavourite', isFavourite)"
          />
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import FavouriteButton from '@/components/candidates/FavouriteButton.vue';
import SocialNetworksMixin from '@/components/mixins/SocialNetworksMixin.vue';
import cutString from '@/utils/text';

export default {
  name: 'PublicOfferCardV3',
  components: {
    FavouriteButton,
  },
  mixins: [
    SocialNetworksMixin,
  ],
  props: {
    offer: {
      type: Object,
      required: true,
    },
    fromOfferUuid: {
      type: String,
      required: false,
      default: '',
    },
    disableFavourite: {
      type: Boolean,
      default: false,
    },
    showPerks: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    offer_link() {
      const link = {
        name: 'candidate:offer',
        params: {
          uuid: this.offer.uuid,
        },
      };
      if (this.fromOfferUuid) {
        link.query = { fromOfferUuid: this.fromOfferUuid };
      }
      return link;
    },
  },
  methods: {
    cutString(text) {
      return cutString(text, 250);
    },
    jobIcon() {
      if (!this.offer) {
        return 'fal fa-circle-notch fa-spin';
      }
      const iconDict = {
        'Operaciones y Logística': 'fal fa-dolly',
        'Administración y Finanzas': 'fal fa-analytics',
        RRHH: 'fal fa-id-badge',
        Comercial: 'fal fa-briefcase',
        Marketing: 'fal fa-lightbulb-on',
        Tecnología: 'fal fa-phone-laptop',
        Seguridad: 'fal fa-shield-check',
      };
      return iconDict[this.offer.transformed_area] ? iconDict[this.offer.transformed_area]
        : 'fal fa-question-circle';
    },
    contractType() {
      const contractTypeDict = {
        FULL_TIME: 'Full time',
        PART_TIME: 'Part time',
        CONTRACTOR: 'Honorarios',
        TEMPORARY: 'Temporal plazo fijo',
        INTERN: 'Práctica profesional',
        VOLUNTEER: 'Voluntario',
        PER_DIEM: 'Por día',
        OTHER: 'Otros',
      };
      return contractTypeDict[this.offer.contract_type_text];
    },
  },
};
</script>

<style scoped>
.offer-card {
  box-sizing: border-box;
  height: auto;
  width: auto;
  border: 1px solid rgba(0,0,0,0.12);
  border-radius: 6px;
  box-shadow: none;
}
.offer-card-responsive{
  box-sizing: border-box;
  height: auto;
  width: auto;
  border: 1px solid rgba(0,0,0,0.12);
  border-radius: 6px;
  box-shadow: none;
}
.first-card{
  border-radius: 6px;
  background-color: #E0E0E0;
}
.offer-title {
  height: 32px;
  width: 156px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 32px;
}
.offer-title-responsive {
  height: 20px;
  width: 174px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.47px;
  line-height: 20px;
}
.offer-subtitle {
  height: 22px;
  width: 399px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
}
.offer-subtitle-responsive {
  height: 18px;
  width: 206px;
  color: #212121;
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0.09px;
  line-height: 12.14px;
  margin-bottom: 0;
}
.icon-company {
  height: 100%;
  width: 100%;
  border-radius: 6px;
}
.icon-company-responsive {
  height: 43px;
  width: 43px;
  border-radius: 6px;
}
.offer-type-contract {
  height: 32px;
  width: 79px;
  color: rgba(0,0,0,0.26);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 32px;
}
.time-since-created {
  color: rgba(0,0,0,0.6);
  font-size: 14px;
  letter-spacing: 0.1px;
  text-align-last: start;
}
.time-since-created-responsive{
  color: rgba(0,0,0,0.6);
  font-size: 10px;
  letter-spacing: 0.07px;
  line-height: 12.14px;
}
</style>
