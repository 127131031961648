var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$vuetify.breakpoint.xsOnly
    ? 'offer-card' : 'offer-card-responsive'},[_c('v-card-text',{class:_vm.$vuetify.breakpoint.xsOnly
      ? 'pt-2' : ''},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{class:_vm.$vuetify.breakpoint.xsOnly
          ? 'mt-1 pl-0' : '',attrs:{"xs2":"","md1":""}},[_c('v-avatar',{class:_vm.$vuetify.breakpoint.xsOnly
            ? 'icon-company' : 'icon-company-responsive',attrs:{"color":"#E0E0E0","tile":"","size":_vm.$vuetify.breakpoint.xsOnly
            ? '43' : '78'}},[_c('v-icon',{attrs:{"color":"E9E9E9"}},[_vm._v(" "+_vm._s(_vm.jobIcon())+" ")])],1)],1),_c('v-flex',{class:_vm.$vuetify.breakpoint.mdAndUp
          ? 'pl-3 d-flex align-center' : 'pl-2',attrs:{"xs8":"","md10":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{class:_vm.$vuetify.breakpoint.xsOnly
              ? 'mb-2' : 'mt-0',attrs:{"xs12":"","md7":""}},[(_vm.offer.public_url)?[_c('router-link',{class:_vm.$vuetify.breakpoint.xsOnly
                  ? 'link_to_offer offer-title-responsive' : 'link_to_offer offer-title',staticStyle:{"text-decoration":"None"},attrs:{"to":_vm.offer_link}},[_vm._v(" "+_vm._s(_vm.offer.computed_title)+" ")]),_c('span',{class:_vm.$vuetify.breakpoint.xsOnly
                  ? 'link_to_offer offer-title-responsive' : 'link_to_offer offer-type-contract'},[_vm._v(" "+_vm._s(_vm.contractType())+" ")]),(_vm.offer.is_brand_new)?_c('v-chip',{attrs:{"color":"cyan darken-1","dark":"","small":""}},[_c('b',[_vm._v("Nuevo")])]):_vm._e(),(_vm.offer.user_is_applying)?_c('v-chip',{attrs:{"color":"green","small":"","dark":""}},[_c('b',[_vm._v("Ya postulaste")])]):_vm._e()]:[_vm._v(" "+_vm._s(_vm.offer.computed_title)+" "),_c('v-chip',{attrs:{"color":"gray","small":"","dark":""}},[_c('b',[_vm._v("Cerrado")])])],_c('p',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'offer-subtitle-responsive mb-0' : 'offer-subtitle mb-0'},[(!_vm.offer.is_confidential)?[_vm._v(" "+_vm._s(_vm.offer.company)+", ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.offer.city_name)+", "+_vm._s(_vm.offer.region_name)+" ")],2)],2),(_vm.showPerks)?_c('v-flex',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? 'mt-3 pl-5' : 'mt-0 pb-1',attrs:{"xs12":"","md2":""}},_vm._l((_vm.offer.perks),function(perk,index){return _c('v-tooltip',{key:(perk + "-" + index),staticClass:"pl-2",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" "+_vm._s(perk.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(perk.name))])])}),1):_vm._e(),_c('v-flex',{class:_vm.$vuetify.breakpoint.lgAndUp
              ? 'pl-5' : 'mt-0 py-0',attrs:{"xs12":"","md3":""}},[_c('p',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'time-since-created-responsive mb-0' : 'time-since-created'},[_vm._v(" Publicado "+_vm._s(_vm.offer.time_since_created)+" ")])])],1)],1),_c('v-flex',{staticClass:"text-xs-right mt-0",attrs:{"xs2":"","md1":"","lg1":"","align-self-start":""}},[_c('FavouriteButton',{staticClass:"mt-0",attrs:{"offer":_vm.offer},on:{"setFavourite":function (isFavourite) { return _vm.$emit('setFavourite', isFavourite); }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }